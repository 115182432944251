import appStrings from '@/app/utility/string.utility';
import responseModal from '../../../shared/responseModal';
import commonHelper from '@/app/utility/common.helper.utility';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';

export default {
  name: 'headerRfp',
  props: ['headerAllData'],
  components: {
    responseModal,
    UploadDocument
  },
  data() {
    return {
      loading: false,
      leName: {
        value: null,
        text: null
      },
      rfpNumber: null,
      startDate: null,
      endDate: null,
      description: null,
      userDepartment: {
        value: null,
        text: null
      },
      rfpType: {
        value: null,
        text: null
      },
      approvalStatus: {
        value: 'DRAFT',
        text: 'DRAFT'
      },
      rfpStatus: {
        value: 'DRAFT',
        text: 'DRAFT'
      },
      version: 0,
      showValueSetModal: false,
      vsetCode: null,
      parent_value_set_id: null,
      editMode: true,
      showOpenUserDepartment: false,
      userDepartmentData: [],
      userDepartmentFields: [
        {
          key: 'concatenated_segment'
        },
        {
          key: 'concatenated_segment_meaning'
        }
      ],
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      userDepartmentTotalRows: null,
      userDepartmentCurrentPage: 1,
      rfpHeaderId: 0,
      currentDate: null,
      responseMsg: null,
      rfpBasis: 'item',
      rfpClosedFlag: false,
      showOpenClosedRfpModal: false,
      rfpInitiateBidFlag: false,
      remarks: null,
      autofocus: true,
      headerRemarks: null,
      userId: null,
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      showHistory: false,
      historyType: null,
      popoverContent: null,
      currentPage: 1,
      documentList: [],
      rfpExtensionId: null
    };
  },
  mounted() {
    this.userId = this.$store.state.auth.userId;
    this.eventBus.$emit('itemBasis', this.rfpBasis);
    const currentDate = new Date();
    this.currentDate = currentDate.toISOString().split('.')[0];
    this.currentDate = this.currentDate.substring(
      0,
      this.currentDate.length - 3
    );
    if (this.headerAllData) {
      if (+(this.headerAllData.user_id) !== +(this.userId)) {
        this.rfpClosedFlag = true;
        this.eventBus.$emit('rfpClosedFlag', this.rfpClosedFlag);
      }
      this.getParentToChildData(this.headerAllData);
    }
    this.formatDate();
    this.getUploadedDocDtl();
  },
  computed: {
    minEndDate() {
      if (!this.endDate) return null;
      const endDateTime = new Date(this.endDate);
      endDateTime.setDate(endDateTime.getDate() + 1);
      return endDateTime.toISOString().slice(0, 16);
    }
  },
  created() {
    this.eventBus.$on('userDepartment', userDepartmentResponse => {
      this.userDepartment = {
        value: userDepartmentResponse.concatenated_segment,
        text: userDepartmentResponse.concatenated_segment_meaning
      };
    });
    this.eventBus.$on('roundStatus', roundStatus => {
      this.roundStatus = roundStatus;
      if (this.roundStatus === 'CLOSED') {
        this.rfpStatus = {
          value: 'CLOSED FOR BIDDING',
          text: 'CLOSED FOR BIDDING'
        };
      } else if (this.roundStatus === 'OPEN') {
        this.rfpStatus = {
          value: 'OPEN FOR BIDDING',
          text: 'OPEN FOR BIDDING'
        };
      }
    });
    this.eventBus.$on('rfpInitiateBidFlag', rfpInitiateBidFlag => {
      this.rfpInitiateBidFlag = rfpInitiateBidFlag;
    });
    this.eventBus.$on('approvalstatus', approvalstatus => {
      this.approvalStatus = {
        value: approvalstatus,
        text: approvalstatus
      };
    });
    this.eventBus.$on('rfpExtensionId', rfpExtensionId => {
      this.rfpExtensionId = rfpExtensionId;
      if (this.rfpExtensionId) {
        this.getHeaderData();
      }
    });
  },
  watch: {
    userDepartmentCurrentPage: function() {
      this.getUserDepartmentData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUserDepartmentData();
    }
  },
  methods: {
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.RFP_TYPE) {
        this.rfpType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DEPARTMENT) {
        this.userDepartment = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'RFP_TYPE') {
        this.rfpType = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.leName = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'USER_DEPARTMENT') {
        this.userDepartment = {
          value: null,
          text: null
        };
      }
    },
    clearDateTime(type) {
      if (type === 'startDateSpecific') {
        this.startDate = null;
      } else if (type === 'endDateSpecific') {
        this.endDate = null;
      }
    },
    openCloseResponseModal(item) {
      if (!this.leName.text) {
        alert('Please select Legal Entity first!');
      } else {
        if (item === 'USER_DEPARTMENT') {
          this.showOpenUserDepartment = true;
          this.getUserDepartmentData();
        }
      }
    },
    userDepartmentModal(flag) {
      this.showOpenUserDepartment = flag;
    },
    selectedUserDepartments(flag) {
      this.showOpenUserDepartment = flag;
    },
    getUserDepartmentData() {
      const payload = {
        concatenated_segment: null,
        le_id: this.leName.value,
        _page: this.userDepartmentCurrentPage - 1,
        _limit: this.perPage
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getUserDepartmentData', payload)
        .then(response => {
          if (response.status === 200) {
            this.userDepartmentData = response.data.data.page;
            this.userDepartmentTotalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // postHeaderData() {
    //   if (
    //     this.leName.value &&
    //     this.startDate &&
    //     this.endDate &&
    //     this.rfpBasis &&
    //     this.userDepartment.value &&
    //     this.rfpType.value &&
    //     this.approvalStatus.value &&
    //     this.rfpStatus.value
    //   ) {
    //     if (this.endDate <= this.startDate) {
    //       alert('End Date cannot be less than Start Date');
    //     } else if(this.startDate < this.currentDate) {
    //       alert('Start Date cannot be less than Current Date');
    //     } else {
    //       let payload = {
    //         rfp_header_id: this.rfpHeaderId,
    //         rfp_number: this.rfpNumber,
    //         approval_status: this.approvalStatus.value,
    //         start_date: this.startDate,
    //         end_date: this.endDate,
    //         rfp_status: this.rfpStatus.value,
    //         description: this.description,
    //         rfp_type: this.rfpType.value,
    //         user_department: this.userDepartment.value,
    //         le_id: +this.leName.value,
    //         le_name: this.leName.text,
    //         rfp_basis: this.rfpBasis
    //       };
    //       this.loading = true;
    //       this.$store
    //         .dispatch('rfp/postRfpHeaderData', payload)
    //         .then(response => {
    //           if (response.status === 200) {
    //             this.responseMsg = response.data.message;
    //             this.makeToast(this.responseMsg, 'success');
    //             this.rfpHeaderId = response.data.data.rfp_header_id;
    //             this.eventBus.$emit('headerId', this.rfpHeaderId);
    //             this.getHeaderData();
    //             this.editMode = false;
    //           }
    //         })
    //         .catch(err => {
    //           this.loading = false;
    //           this.makeToast(err, 'success');
    //         });
    //     }
    //   } else {
    //     alert('Please enter mandatory fields!');
    //   }
    // },
    postHeaderData() {
      if (
        this.leName.value &&
        this.startDate &&
        this.endDate &&
        this.rfpBasis &&
        this.userDepartment.value &&
        this.rfpType.value &&
        this.approvalStatus.value &&
        this.rfpStatus.value
      ) {
        let startDate = new Date(this.startDate);
        let endDate = new Date(this.endDate);
        let currentDate = new Date(this.currentDate);
        let maxEndDate = this.addMonths(startDate, 6);
        if (endDate <= startDate) {
          alert('End Date cannot be less than or equal to Start Date');
        } else if (startDate < currentDate) {
          alert('Start Date cannot be less than Current Date');
        } else if (endDate > maxEndDate) {
          alert('End Date cannot be more than 6 months after Start Date');
        } else {
          let payload = {
            rfp_header_id: this.rfpHeaderId,
            rfp_number: this.rfpNumber,
            approval_status: this.approvalStatus.value,
            start_date: this.startDate,
            end_date: this.endDate,
            rfp_status: this.rfpStatus.value,
            description: this.description,
            rfp_type: this.rfpType.value,
            user_department: this.userDepartment.value,
            le_id: +this.leName.value,
            le_name: this.leName.text,
            rfp_basis: this.rfpBasis
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/postRfpHeaderData', payload)
            .then(response => {
              if (response.status === 200) {
                this.responseMsg = response.data.message;
                this.makeToast(this.responseMsg, 'success');
                this.rfpHeaderId = response.data.data.rfp_header_id;
                this.eventBus.$emit('headerId', this.rfpHeaderId);
                this.getHeaderData();
                this.editMode = false;
              } else {
                this.responseMsg = response.response.data.message;
                this.makeToast(this.responseMsg, 'danger');
              }
            })
            .catch(err => {
              this.loading = false;
              this.makeToast(err, 'success');
            });
        }
      } else {
        alert('Please enter mandatory fields!');
      }
    },
    getHeaderData() {
      let payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpHeaderData', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.eventBus.$emit('headerData', result);
            this.leName = {
              value: result.le_id,
              text: result.le_name
            };
            this.rfpNumber = result.rfp_number;
            this.startDate = result.start_date;
            this.startDate = this.convertDateFormat(this.startDate);
            this.endDate = result.end_date;
            this.endDate = this.convertDateFormat(this.endDate);
            this.userDepartment = {
              value: result.user_department,
              text: result.user_department_meaning
            };
            this.rfpType = {
              value: result.rfp_type,
              text: result.rfp_type_meaning
            };
            this.eventBus.$emit('rfpType', this.rfpType.value);
            this.approvalStatus = {
              value: result.approval_status,
              text: result.approval_status
            };
            this.rfpStatus = {
              value: result.rfp_status,
              text: result.rfp_status
            };
            this.eventBus.$emit('rfpStatus', this.rfpStatus);
            if (this.rfpStatus.text === 'CLOSED') {
              this.rfpClosedFlag = true;
              this.eventBus.$emit('rfpClosedFlag', this.rfpClosedFlag);
            }
            this.version = result.version;
            this.description = result.description;
            this.headerRemarks = result.remarks;
            this.editMode = false;
            this.rfpBasis = result.rfp_basis;
            this.eventBus.$emit('itemBasis', this.rfpBasis);
            this.userId = result.buyer_emp_id;
            this.eventBus.$emit('userId', this.userId);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000
      });
    },
    handleEditMode() {
      this.editMode = !this.editMode;
    },
    getParentToChildData(data) {
      this.rfpHeaderId = data.rfp_header_id;
      this.selectedGridData = {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.getHeaderData();
    },
    convertDateFormat(inputDate) {
      const [datePart, timePart] = inputDate.split(' ');
      const [day, month, year] = datePart.split('-');
      const convertedDate = `${year}-${month}-${day}T${timePart}`;

      return convertedDate;
    },

    changeRfpBasis(item) {
      this.rfpBasis = item;
      this.eventBus.$emit('itemBasis', this.rfpBasis);
    },
    closeRfp() {
      if (this.rfpStatus.value === 'OPEN FOR BIDDING') {
        alert('Please close negotiation round!');
      } else {
        this.showOpenClosedRfpModal = true;
      }
    },
    closeRfpModal(flag) {
      this.showOpenClosedRfpModal = flag;
    },
    closeRfpConfirm(flag) {
      if (flag === 'yes') {
        if (this.remarks) {
          const payload = {
            remarks: this.remarks,
            rfp_header_id: this.rfpHeaderId
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/closeRfp', payload)
            .then(response => {
              if (response.status === 200) {
                this.responseModal = response.data.message;
                this.makeToast(this.responseMsg, 'success');
                this.getHeaderData();
              }
              this.loading = false;
            })
            .catch(err => {
              this.loading = false;
              this.makeToast(err, 'success');
            });
          this.showOpenClosedRfpModal = false;
        } else {
          alert('Please Enter Remarks first');
        }
      } else {
        this.showOpenClosedRfpModal = false;
      }
    },
    clearValueSetModal(type) {
      if (type === 'LEGAL_ENTITY_LIST') {
        this.leName = {
          value: null,
          text: null
        };
      } else if (type === 'USER_DEPARTMENT') {
        this.userDepartment = {
          value: null,
          text: null
        };
      } else if (type === 'RFP_TYPE') {
        this.rfpType = {
          value: null,
          text: null
        };
      }
    },

    getOpenKmAuthTokenUploadDocument() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
      this.getUploadedDocDtl();
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: 100,
        menu_id: 446,
        sub_menu_id: 0,
        record_id: this.rfpHeaderId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.documentList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            } else {
              // alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: 446
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    formatDate() {
      let date = new Date();
      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let year = date.getFullYear();
      let hours = String(date.getHours()).padStart(2, '0');
      let minutes = String(date.getMinutes()).padStart(2, '0');
      this.startDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    },
    addMonths(date, months) {
      let newDate = new Date(date);
      newDate.setMonth(newDate.getMonth() + months);
      if (newDate.getDate() !== date.getDate()) {
        newDate.setDate(0);
      }

      return newDate;
    },
    checkStartDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
      if (this.startDate < formattedDateTime) {
        alert('Start Date & Time cannot be less than Current Date & Time');
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('userDepartment');
    this.eventBus.$off('rfpClosedFlag');
    this.eventBus.$off('itemBasis');
    this.eventBus.$off('rfpStatus');
    this.eventBus.$off('headerData');
    this.eventBus.$off('rfpExtensionId');
  }
};
