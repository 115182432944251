import VueCkeditor from 'vue-ckeditor5';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';

export default {
  name: 'termsAndConditions',
  props: ['rfphdrid', 'userId', 'headerAllData', 'tncData'],
  components: {
    VueCkeditor,
    'vue-ckeditor': VueCkeditor.component,
    UploadDocument
  },
  data() {
    return {
      editMode: true,
      summary: null,
      editors: {
        classic: ClassicEditor
      },
      editorData: '',
      editorConfig: {
        removePlugins: [
          'CKFinderUploadAdapter',
          'CKFinder',
          'EasyImage',
          'Image',
          'ImageCaption',
          'ImageStyle',
          'ImageToolbar',
          'ImageUpload',
          'MediaEmbed',
          'Table'
        ],
        isReadOnly: true
      },
      loader: false,
      openKmAuth: null,
      showHistory: false,
      historyType: null,
      currentPage: 1,
      rfpHeaderId: null,
      userid: null,
      rfpClosedFlag: false,
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      responseMsg: null,
      termId: 0,
      formType: 'rfpTnc',
      rfpInitiateBidFlag: false,
      isDisabled: false,
      documentList: []
    };
  },
  watch: {
    isDisabled(newVal) {
      this.setEditorDisabledState(newVal);
    },
  },
  mounted() {
    if (this.rfphdrid) {
      this.rfpHeaderId = this.rfphdrid;
      // this.getHeaderData();
    }
    if (this.userId) {
      this.userid = this.userId;
    }
    if (this.headerAllData) {
      this.selectedGridData = {
        le_id: this.headerAllData.le_id,
        le_name: this.headerAllData.le_name,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
    }
    if (this.tncData && this.tncData.length > 0) {
      this.termId = this.tncData[0].terms_id;
      setTimeout(() => {
        this.summary = this.tncData[0].rfp_t_and_c;
      }, 500);
    }
    this.$nextTick(() => {
      this.setEditorDisabledState(this.isDisabled);
    });
    this.getUploadedDocDtl()
  },
  created() {
    this.eventBus.$on('rfpClosedFlag', rfpClosedFlag => {
      this.rfpClosedFlag = rfpClosedFlag;
    });
    this.eventBus.$on('userId', userId => {
      this.userid = userId;
    });
    this.eventBus.$on('headerId', rfpHeaderId => {
      this.rfpHeaderId = rfpHeaderId;
    });
    this.eventBus.$on('rfpInitiateBidFlag', rfpInitiateBidFlag => {
      this.rfpInitiateBidFlag = rfpInitiateBidFlag;
    });
  },
  methods: {
    getOpenKmAuthTokenUploadDocument() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice');
              this.editMode = false;
              this.isDisabled = true;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
      this.getUploadedDocDtl();
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: 100,
        menu_id: 446,
        sub_menu_id: 85,
        record_id: this.termId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.documentList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            }
             else {
              // alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000,
      });
    },
    getHeaderData() {
      let payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpHeaderData', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.summary = result.rfp_t_and_c;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    postTermsAndConditions(item) {
        let payload = {
          terms_id: this.termId,
          rfp_header_id: this.rfpHeaderId,
          rfp_t_and_c: this.summary,
          upload_doc: null
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/postTermsAndConditions', payload)
          .then(response => {
            if (response.status === 200) {
              this.termId = response.data.data.terms_id;
              // this.responseMsg = response.data.message;
              // this.makeToast(this.responseMsg, 'success');
              if (item === 'UPLOAD') {
                this.getOpenKmAuthTokenUploadDocument();
              }
            } else {
              this.responseMsg = response.response.data.message;
              this.makeToast(this.responseMsg, 'danger');
            }
          })
          .catch(err => {
            this.loading = false;
            this.makeToast(err, 'danger');
          });
      this.getTermsAndConditions();
    },
    getTermsAndConditions() {
      let payload = {
        terms_id: this.termId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getTermsAndConditions', payload)
        .then(response => {
          if (response.status === 200) {
            setTimeout(() => {
              this.summary = response.data.data.rfp_t_and_c;
            }, 500);
            this.editMode = false;
            this.isDisabled = true;
            if (this.summary === null) {
              this.getOpenKmAuthTokenUploadDocument();
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editTermsAndConditions() {
      this.formType = 'rfpTnc';
      this.getOpenKmAuthTokenUploadDocument();
    },
    viewTermsAndConditions() {
      this.formType = 'rfpTncView';
      this.getOpenKmAuthTokenUploadDocument();
    },
    alterEditMode() {
      this.editMode = !this.editMode;
      this.isDisabled = !this.isDisabled;
      this.editorConfig.isReadOnly = !this.editorConfig.isReadOnly;
    },
    setEditorDisabledState(isDisabled) {
      if (this.$refs.ckeditor) {
        const editorInstance = this.$refs.ckeditor.instance;
        if (editorInstance) {
          editorInstance.isReadOnly = isDisabled;
        }
      }
    },
  }
};
