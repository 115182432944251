import generateComparative from "./generateComparative";


export default {
  name: 'round',
  props: ['roundProps', 'itemBasis', 'rfpHeaderId', 'negotiationSequence'],
  components: {
    generateComparative
  },
  data() {
    return {
      roundData: [],
      roundFields: [
        {
          key: 'name',
          class: 'text-center'
        },
        {
          key: 'rfp_number',
          label: 'RFP Number',
          class: 'text-center'
        },
        {
          key: 'pan_no',
          class: 'text-center'
        },
        {
          key: 'email',
          class: 'text-center'
        },
        {
          key: 'bid_submit_status',
          label: 'Bid Status',
          class: 'text-center'
        },
        // {
        //   key: 'view_bid',
        //   class: 'text-center'
        // }
      ],
      currentPage: 1,
      perPage: 50,
      showOpenGenerateComparativeModal: false,
      loading: false,
      rfpBasis: null,
      popoverContent: null
    };
  },
  created() {
  },
  mounted() {
    if (this.roundProps) {
      this.getRoundData(this.roundProps);
    }
    this.rfpBasis = this.itemBasis;
  },
  methods: {
    getRoundData(props) {
      let payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        negotiation_hdr_id: props.negotiation_hdr_id,
        rfp_header_id: props.rfp_header_id
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getNegotiationRoundData', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            let result = response.data.data.page;
            this.roundData = result;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openGenerateComparativeModal() {
      this.showOpenGenerateComparativeModal = true;
    },
    generateComparativeModal(flag) {
      this.showOpenGenerateComparativeModal = flag;
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: 446
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    }
  }
};
